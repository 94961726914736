@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vollkorn:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  position: relative;
  font-family: "Roboto", sans-serif;

  background: #f7f7f7;
}
.container {
  width: 80%;
  margin: auto;
}
.flex {
  display: flex;
}
.hover {
  cursor: pointer;
}
.center {
  justify-content: center;
}
.link {
  text-decoration: none;
  color: inherit;
}
.btn-submit {
  color: white !important;
  background: #32cd32 !important;
}
.btn-info {
  color: white !important;
  background: dodgerblue !important;
}
.btn-remove {
  color: white !important;
  background: red !important;
}
.loading {
  text-align: center;
  padding: 90px 0px;
}

.mt-10 {
  margin-top: 10px;
}
/* HEADER CSS */
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 0px;
  height: 70px;
}
.logo {
  height: 50px;
  border-radius: 50%;
}
.profile {
  height: 100%;
  border-radius: 50%;
}
.profile {
  padding-left: 30px;
}
/* HEADER CSS */

/* SEARCH CSS */
.search {
  margin-top: 20px;
}
.search-input {
  width: 35%;
}
/* SEARCH CSS */

/* MAIN CSS */
.main-nav {
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: center;
}
.col-4 {
  width: 245px;
  border-radius: 5px;
  background: white;
  margin: 5px 5px;
}
.nav-card {
  padding: 50px 0px;
  text-align: center;
}
.report-card {
  margin: 10% 15%;
  text-align: center;
}
.nav-text {
  padding-top: 10px;
}
.nav-hover:hover {
  cursor: pointer;
  border: 1px solid dodgerblue;
}

/* MAIN CSS */

/* ADD CSS */
.add-card {
  width: 606px;
  padding: 30px;
  margin: auto;
  border-radius: 5px;
  background-color: white;
}
.text-area {
  margin-top: 5px;
}
.text-100 {
  width: 100%;
  margin: 0px 10px;
}
.text-50 {
  width: 50%;
  margin: 0px 10px;
}
.input-50 {
  width: 100%;
}
.submission {
  display: flex;
  margin-top: 20px;
  margin-right: 10px;
  justify-content: end;
}
/* ADD CSS */

/* LIST CSS */
.list-table {
  margin-top: 25px;
}
.btn-complete {
  border: none;
  background: #54c717;
  color: white;
  padding: 2px;
}
.btn-danger {
  border: none;
  background: #ff4d4d;
  color: white;
  padding: 2px;
}
.btn-info {
  border: none;
  background: dodgerblue;
  color: white;
  padding: 2px;
}
.btn-complete:hover,
.btn-danger:hover {
  cursor: pointer;
  border: 1px solid dodgerblue;
}
/* LIST CSS */

/* MODAL CSS */
.info-modal {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 255, 0.3);
}
.info-close {
  display: flex;
  justify-content: flex-end;
  border: none;
  padding: 5px;
  background-color: "red";
}
.info-close:hover {
  cursor: pointer;
}
.info-modal-card {
  background: white;
  width: 400px;
  border-radius: 5px;
  padding: 30px;
  text-align: center;
}
/* MODAL CSS */

/* REPORT MODAL CSS */
.report-modal {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 255, 0.3);
}
.info-close {
  display: flex;
  justify-content: flex-end;
  border: none;
  padding: 5px;
  background-color: "red";
}
.info-close:hover {
  cursor: pointer;
}
.report-modal-card {
  background: white;
  width: 95%;
  max-height: 85%;
  border-radius: 5px;
  padding: 30px;
  text-align: center;
}
/* REPORT MODAL CSS */

/* COMPANY INFO */
.company-info {
  margin: 30px auto;
  width: 1000px;
}
.left-info {
  /* display: flex;
  justify-content:center;
  flex-direction: column;   */

  width: 250px;
}
.main-info {
  background-color: white;
  text-align: center;
  padding: 30px;
  margin-bottom: 10px;
  position: relative;
}
.btn-edit {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  color: white;
  background-color: #51a9ff;
  padding: 5px 8px;
}
.left-text {
  margin-top: 10px;
  font-size: 12px;
}
.info {
  margin-top: 5px;
}
.company-details {
  width: 700px;
  margin-left: 20px;
}
.full-details {
  background: white;
  margin-top: 5px;
  padding: 10px;
  position: relative;
}
.green {
  background: #52fc00 !important;
  color: white !important;
}
.red {
  background: #ff3333 !important;
  color: white !important;
}
/* COMPANY INFO */

/* REGISTRATION CSS */
.form-position {
  justify-content: center;
  margin: 12px 0px;
}

.form-position div {
  background: white;
  padding: 10px 15px;
  border-radius: 50%;
  margin: 5px;
}
.form-position div:hover {
  border: 1px solid dodgerblue;
  color: dodgerblue;
  cursor: pointer;
}

.submission {
  width: 606px;
  margin: 15px auto;
  justify-content: space-between;
}
.btn-add-form {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}
.toggle-buttons button {
  margin-left: 10px;
}
/* REGISTRATION CSS */

/* LICENSE CSS */
.license {
  position: relative;
}

.license-company {
  position: absolute;
  top: 48%;
  left: 18%;
  font-size: 2.5vw;
  font-size: bold;
  font-family: "Courgette", cursive;
}
.license-sector {
  position: absolute;
  top: 55%;
  left: 41%;
  font-size: 2.5vw;
  font-size: bold;
  font-family: "Courgette", cursive;
}
.license-chairman {
  position: absolute;
  top: 62%;
  left: 40%;
  font-size: 2.5vw;
  font-size: bold;
  font-family: "Courgette", cursive;
}
.license-no {
  position: absolute;
  top: 31%;
  left: 5.4%;
  font-size: 2.8vw;
  font-size: bold;
  font-family: "Vollkorn", serif;
}
.license-date {
  position: absolute;
  top: 87.9%;
  left: 45%;
  font-size: 1.5vw;
  font-size: bold;
  font-family: "Vollkorn", serif;
}
.license-valid {
  position: absolute;
  top: 80.5%;
  left: 83.4%;
  font-size: 1.5vw;
  font-family: "Vollkorn", serif;
}
.license-expires {
  position: absolute;
  top: 88%;
  left: 82.4%;
  font-size: 1.5vw;
  font-family: "Vollkorn", serif;
}
.license-buttons {
  margin: 10px 0px;
  justify-content: space-between;
}
.print {
  color: white !important;
  background: dodgerblue !important;
}
/* LICENSE CSS */

/* USERLIST CSS */
.user-list table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 200px;
}
.user-list td,
.user-list th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.user-list tr:nth-child(even) {
  background-color: #dddddd;
}
/* USERLIST CSS */

/* LOGS CSS */
.CREATED {
  color: green;
}
.EDITED {
  color: blue;
}
.DELETED {
  color: red;
}
/* LOGS CSS */
/* LOGIN CSS */
.login-card {
  background: white;
  width: 340px;
  margin: 100px auto;
  padding: 20px;
  text-align: center;
}
/* LOGIN CSS */

/* PAGINATION CSS */
.pagination {
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.pagination a {
  padding: 10px;
}
.pagination li {
  display: inline-block;
  /* padding:10px; */
  margin: 2px;
  background-color: white;
  border-radius: 5px;
  color: black;
}
.pagination li:hover {
  cursor: pointer;
  background-color: #51a9ff;
}
.active {
  background-color: dodgerblue !important;
  color: white !important;
}
/* PAGINATION CSS */

/* FOOTER CSS */
.footer {
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px 40px;
  justify-content: flex-end;
  align-items: center;
}

.footer-circle:hover {
  cursor: pointer;
}

/* FOOTER CSS */
